import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {first, map, switchMap, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {ControlService} from './control.service'
import {SaveData, SaveDataApplicant} from './data.service'

export interface Customer {
  /**
   * Id is uuid, backends will have to handle the unique personnummer thing.
   */
  id?: string

  /**
   * Version, used on server only
   */
  version?: number

  /**
   * Name? Applicant?
   */
  saveData: SaveData

  /**
   * Sub, personnummer is available on top level
   */
  sub: string

  /**
   * user is the sparbanken user
   */
  user: {
    name: string
    sId: string
    email: string
  }

  /**
   * A tuple, telling if we have signatures
   * 1. Printed
   * 2. Sent for signing digital
   * 3. Signed digital (applicant)
   * 4. Signed digital (co applicant)
   *
   * See enum above
   */
  signatures: [boolean, boolean, boolean, boolean]

  /**
   * The date when the customer was created (or re-created)
   * it is set by the server.
   */
  started: number

  /**
   * The date when the customer should be deleted
   * it is set by the server.
   */
  gdprRemove: number

  /**
   * Only used to se what is checked, used when delete many
   */
  checked?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public customers$ = new BehaviorSubject<Customer[]>([])

  public currentCustomer$ = new BehaviorSubject<Customer | undefined>(undefined)

  /**
   * A Map of all existing personnummers and their started
   * "rådgivning" with its Id for easy access.
   */
  public existingCustomers = new Map<string, string>()

  constructor(
    private controlService: ControlService,
    private httpClient: HttpClient
  ) {
    this.getAllCustomers().subscribe()
    this.buildCustomerMap()
  }

  public getAllCustomers(): Observable<Customer[]> {
    const url = `${environment.apiUrl}/customers`
    return this.httpClient.get<Customer[]>(url).pipe(
      tap((customers: Customer[]) => {
        // This is a temporary hack to make sure we have the data
        customers.forEach((customer: Customer) => {
          customer.signatures = customer.signatures || [false, false]
          customer.saveData = customer.saveData || {}
          customer.saveData.proposal = customer.saveData.proposal || {loans: []}
        })
      }),
      tap((customers: Customer[]) => this.customers$.next(customers))
    )
  }

  public saveCustomer(customer: Customer): Observable<Customer> {
    const url = `${environment.apiUrl}/customers/${customer.id}`

    if (this.controlService.locked$.value) {
      // Save nothing if the advice is locked and return current customer
      return of(customer)
    } else {
      return this.httpClient.put<Customer>(url, customer).pipe(
        switchMap((updatedCustomer: Customer) => this.updateCustomer(updatedCustomer)
        )
      )
    }

  }

  public createCustomer(data: SaveData): Observable<Customer> {
    const url = `${environment.apiUrl}/customers`
    return this.httpClient.put<Customer>(url, data).pipe(
      switchMap((customer: Customer) => this.addCustomer(customer))
    )
  }

  public deleteCustomer(id: string): Observable<void> {
    const url = `${environment.apiUrl}/customers/${id}`
    return this.httpClient.delete<void>(url).pipe(
      switchMap(() => this.removeCustomer(id))
    )
  }

  public getCustomer(id: string): Observable<Customer> {
    const url = `${environment.apiUrl}/customers/${id}`
    return this.httpClient.get<Customer>(url).pipe(
      tap((customer: Customer) => this.currentCustomer$.next(customer)
      )
    )
  }

  /**
   *
   * @param id - The ID of the customer
   * @param index - The position to update in the signature array
   *
   */
  public updateSignage(id: string, index: number): Observable<any> {
    return this.customers$.pipe(
      first(),
      switchMap((customers: Customer[]) => {
        const customer = customers.find((c: Customer) => c.id === id) as Customer
        customer.signatures[index] = true
        const url = `${environment.apiUrl}/customers/${customer.id}/signatures`
        return this.httpClient.put(url, customer.signatures)
      })
    )
  }

  /**
   * Internal updates etc. Called by our self to sort out the internals
   * @private
   */
  private addCustomer(customer: Customer): Observable<Customer> {
    return this.customers$.pipe(
      first(),
      map((customers: Customer[]) => {
        const existing = customers.find((cst: Customer) => cst.id === customer.id)
        if (existing) {
          Object.assign(existing, customer)
        } else {
          customers.push(customer)
          this.customers$.next(customers)
        }
        this.currentCustomer$.next(customer)
        return customer
      })
    )
  }

  private updateCustomer(customer: Customer): Observable<Customer> {
    return this.customers$.pipe(
      first(),
      map((customers: Customer[]) => {
        customers.forEach((cst: Customer) => {
          if (cst.id === customer.id) {
            Object.assign(cst, customer)
          }
        })
        this.customers$.next(customers)
        this.currentCustomer$.next(customer)
        return customer
      })
    )
  }

  private removeCustomer(id: string): Observable<void> {
    return this.customers$.pipe(
      first(),
      map((customers: Customer[]) => {
        const filtered = customers.filter((customer: Customer) => customer.id !== id)
        this.customers$.next(filtered)
        this.currentCustomer$.next(undefined)
      })
    )
  }

  private buildCustomerMap(): void {
    this.customers$.subscribe({
      next: (customers: Customer[]) => {
        this.existingCustomers.clear()
        customers
          .filter((customer: Customer) => !!customer.saveData)
          .filter((customer: Customer) => !!customer.saveData.applicants)
          .filter((customer: Customer) => !!customer.saveData.applicants.applicants)
          .forEach((customer: Customer) => {
            customer.saveData.applicants.applicants.forEach((applicant: SaveDataApplicant) => {
              this.existingCustomers.set(applicant.personNummer, customer.id as string)
            })
          })
      }
    })
  }
}
